import React, { useState } from "react"
import Layout from "../../components/layout"
import { Container, Row, Col, ModalBody, Modal } from "reactstrap"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
// import { IoIosInfinite } from 'react-icons/io';
// import { FaRegHandshake } from 'react-icons/fa';
// import { FiEye } from 'react-icons/fi';
import Map1 from "../../images/map1.png"
import Map2 from "../../images/map2.png"
import BlogForm from "./Blog/BlogForm"

const ContactUs = props => {
  const {
    // buttonLabel,
    className,
  } = props

  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const data = useStaticQuery(graphql`
    query MyQuery1 {
      file(relativePath: { eq: "contact.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1220, maxHeight: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  // <StaticQuery
  //     query={graphql`
  //   query {
  //     Banner: file(relativePath: { eq: "contact.jpg" }) {
  //         childImageSharp {
  //           fluid(quality: 90, maxWidth: 1220, maxHeight:500,) {
  //             ...GatsbyImageSharpFluid_withWebp
  //           }
  //         }
  //       }

  //   }
  // `}

  //     render={data => {
  //         const imageData = data.Banner.childImageSharp.fluid
  return (
    <Layout path="/contact/">
      <div className="banner_section">
        <Img
          Tag="section"
          fluid={data.file.childImageSharp.fluid}
          className="startup_background"
        ></Img>
        <div className="startup">
          <div className="startup_canter"></div>
        </div>
      </div>
      <section className="startup_section">
        {/* <div className="contact_section">
                    <div className="contact_map">
                        <Map />
                    </div>
                    <div className="contact_text">
                        <div>
                            <div>
                                <h4>USA</h4>
                                <p><strong>California:</strong> 2410 Camino Ramon, Suite 183, San Ramon 94583</p>
                                <p><strong>Texas:</strong> 101 E. Park Blvd Suite 600 Plano 75074</p>
                                <p><strong>New Jersey:</strong> 1200 Route 22 East Suite 2000 Bridgewater 08807</p>
                                <p><strong>Phone:</strong> 925-353 5850</p>
                                <p><strong>Email:</strong> info@tridentconsultinginc.com</p>
                            </div>
                            <div className="contact_address">
                                <h4>INDIA</h4>
                                <p><strong>Chennai:</strong> No:3/2, Darshan Building, 2nd Main road, Gandhi Nagar, Adyar, Chennai – 600 020</p>
                                <p><strong>Coimbatore:</strong> No: 5-9, Epic Plaza, 4th Floor, Head post office road, Coimbatore – 641 001</p>
                                <p><strong>Tirunelveli:</strong> No: 249, NGO A Colony, Drivers Colony, Tirunelveli – 627 007</p>
                                <p><strong>Phone:</strong> 925-353 5850</p>
                                <p><strong>Email:</strong> info@tridentconsultinginc.com</p>
                            </div>

                        </div>
                    </div>
                </div> */}
        <Container>
          <Row>
            <Col md="6">
              <div className="contact_address">
                <h4>USA</h4>
                <p>
                  <strong>California:</strong> 2410 Camino Ramon, Suite 183, San
                  Ramon 94583
                </p>
                <p>
                  <strong>Texas:</strong> 101 E. Park Blvd, Suite 600, Plano
                  75074
                </p>
                <p>
                  <strong>New Jersey:</strong> 1200 Route 22 East, Suite 2000,
                  Bridgewater 08807
                </p>
                <p>
                  <strong>Austin:</strong> 10920 Gonzales Ranger Pass, Austin
                  78754
                </p>
                <p>
                  <strong>Phone:</strong> 925-353 5850
                </p>
                <p>
                  <strong>Email:</strong> info@tridentconsultinginc.com
                </p>
              </div>
            </Col>
            <Col md="6">
              <div className="contact_address">
                <h4>INDIA</h4>
                <p>
                  <strong>Chennai:</strong> No:3/2, Darshan Building, 2nd Main
                  road, Gandhi Nagar, Adyar, Chennai 600020
                </p>
                <p>
                  <strong>Coimbatore:</strong> No: 5-9, Epic Plaza, 4th Floor,
                  Head post office road, Coimbatore 641001
                </p>
                <p>
                  <strong>Tirunelveli:</strong> No: 249, NGO A Colony, Drivers
                  Colony, Tirunelveli 627007
                </p>
                <p>
                  <strong>Phone:</strong> 925-353 5850
                </p>
                <p>
                  <strong>Email:</strong> info@tridentconsultinginc.com
                </p>
              </div>
            </Col>
            <Col md="6">
              <div className="map">
                <img src={Map1} alt="img" />
              </div>
            </Col>
            <Col md="6">
              <div className="map">
                <img src={Map2} alt="img" />
              </div>
            </Col>
            <Col md="6">
              <div className="contact_address">
                <h4>Canada</h4>
                <p>
                  <strong>Vancouver:</strong> 1771 Robson Street -1334 Vancouver
                  BC V6G 1C9
                </p>
                <p>
                  <strong>Email:</strong> info@tridentconsultinginc.com
                </p>
              </div>
            </Col>
            <Col md="6" />
            {/* <Col md="6">
                            <div className="map">
                                <img src={Map2} alt="img" />
                            </div>
                        </Col> */}
          </Row>
        </Container>
      </section>
      <section>
        {/* <div className="map_section">
                <Map />
                </div> */}
      </section>
      <section className="footer_section">
        <Container>
          <Row>
            <Col md="12">
              <h1>Find out what Trident can do for you.</h1>
              <div className="footer_button">
                <a href onClick={toggle}>
                  Contact Us
                </a>
              </div>
              <Modal
                isOpen={modal}
                toggle={toggle}
                className={className}
                className="form_modal"
              >
                <ModalBody>
                  <BlogForm
                    title="Find out what Trident can do for you."
                    close={() => toggle()}
                  />
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <BlogForm refItem = {()=>{}} title = "Find out what Trident can do for you." redirectTitle="Cotact Us" pathname={props.location.pathname}/> */}
    </Layout>
  )
}
const StyledBackgroundSection = styled(ContactUs)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  margin: 0;
  background-color: inherit !important;
`

export default StyledBackgroundSection
